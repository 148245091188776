<template>
  <v-container>
    <v-data-table
      v-if="windowWidth > 926"
      :headers="headers"
      :items="itemsToShow"
      class="elevation-1"
      item-class="table-row hover-pointer"
      @click:row="redirectToPage"
    >
      <template v-slot:[`item.geo`]="{ item }">
        <span class="assignments-table--geo">{{ item.geo }}</span>
      </template>
      <template v-slot:[`item.taskStatus`]="{ item, value }">
        <PMDashboardDropdownSelector
          :item="item"
          :value="value"
          :statusMode="true"
          :dropdownOptions="taskStatuses"
          :dropdownOptionsLoading="dropdownOptionsLoading"
          @refresh-table-data="updateTableData"
          v-on="$listeners"
        />
      </template>
      <template v-slot:[`item.note`]="{ item }">
        <v-btn
          icon
          large
          color="accent"
          :disabled="true"
          @click="openNote(item)"
        >
        <v-icon>
          mdi-message-outline
        </v-icon>
      </v-btn>
      </template>
    </v-data-table>
    <div class="assignments-table--mobile"
      v-else>
      <div class="assignments-table--mobile__card"
        @click="redirectToPage(item)"
        v-for="(item, index) in itemsToShow" :key="index">
          <p class="assignments-table--mobile__card__geo">{{ item.geo }}</p>
          <div class="assignments-table--mobile__card__wrapper">
            <p class="assignments-table--mobile__card__wrapper--title">
              <pmdashboard_table_icons
                :icon="'pmdashboard_account'"
                class="pmdashboard__task--content-left--icon mr-3"
                />
              Primary
            </p>
            <p class="assignments-table--mobile__card__wrapper--data">{{ item.primary }}</p>
          </div>
          <div class="assignments-table--mobile__card__wrapper">
            <p class="assignments-table--mobile__card__wrapper--title">
              <v-icon class="mr-3">mdi-clock-outline</v-icon>
              Prim. Due Date
            </p>
            <p class="assignments-table--mobile__card__wrapper--data">{{ item.primaryDueDate }}</p>
          </div>
          <div class="assignments-table--mobile__card__wrapper">
            <p class="assignments-table--mobile__card__wrapper--title">
              <pmdashboard_table_icons
                :icon="'pmdashboard_account'"
                class="pmdashboard__task--content-left--icon mr-3"
                />
              Secondary
            </p>
            <p class="assignments-table--mobile__card__wrapper--data">{{ item.secondary ? item.secondary : '-' }}</p>
          </div>
          <div class="assignments-table--mobile__card__wrapper">
            <p class="assignments-table--mobile__card__wrapper--title">
              <v-icon class="mr-3">mdi-clock-outline</v-icon>
              Sec. Due Date
            </p>
            <p class="assignments-table--mobile__card__wrapper--data">{{ item.secondaryDueDate ? item.secondaryDueDate : '-' }}</p>
          </div>
          <div class="assignments-table--mobile__card__wrapper">
            <p class="assignments-table--mobile__card__wrapper--title">
              <pmdashboard_table_icons
                :icon="'pmdashboard_account'"
                class="pmdashboard__task--content-left--icon mr-3"
                />
              QC
            </p>
            <p class="assignments-table--mobile__card__wrapper--data">{{ item.qc ? item.qc : '-' }}</p>
          </div>
          <div class="assignments-table--mobile__card__wrapper">
            <p class="assignments-table--mobile__card__wrapper--title">
              <v-icon class="mr-3">mdi-clock-outline</v-icon>
              QC Due Date
            </p>
            <p class="assignments-table--mobile__card__wrapper--data">{{ item.qcDueDate ? item.qcDueDate : '-' }}</p>
          </div>
          <div class="assignments-table--mobile__card__wrapper">
            <p class="assignments-table--mobile__card__wrapper--title">
              <pmdashboard_table_icons
                :icon="'pmdashboard_status'"
                class="pmdashboard__task--content-left--icon mr-3"
                />
              Status
            </p>
            <p class="assignments-table--mobile__card__wrapper--data">
              <v-chip
                class="ma-0 pmdashboard__task--content-left--chip"
                :color="statusColor(item.assignmentStatus)"
                label
              >
                {{ item.assignmentStatus }}
              </v-chip>
            </p>
          </div>
      </div>
    </div>
    <v-dialog
      max-width="650"
      v-model="noteModal"
      @click:outside="noteModal = false"
      @keydown.esc="noteModal = false"
    >
      <v-card>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">{{ note.split(" / ")[3] }}</div>
            <v-list-item-title class="text-h5 mb-1">
              {{ note.split(" / ")[1] }}
            </v-list-item-title>
            <div class="text--primary mb-3">
              {{ note.split(" / ")[2] }}
            </div>
            <v-list-item-title class="text-h4 mb-1">
              {{ note.split(" / ")[0] }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            <div class="text--primary">
              {{ note.split(" / ")[4] }}
            </div>
            <v-list-item-subtitle>{{
              note.split(" / ")[5]
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions class="justify-end pb-4">
          <v-btn min-width="42px" outlined elevation="1" @click="copyNote"
            ><v-icon>mdi-clipboard-multiple-outline</v-icon></v-btn
          >
          <v-btn min-width="42px" color="secondary" @click="noteModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import pmdashboard_table_icons from "@/assets/icons/pmdashboard_table_icons.vue";
import { DOMAIN, ROUTES } from "@/utils/constants";
import PMDashboardDropdownSelector from "./PMDashboardDropdownSelector.vue";

export default {
  components: {
    PMDashboardDropdownSelector,
    pmdashboard_table_icons
  },
  props: {
    itemsList: {
      type: Array,
      required: true,
    },
    windowWidth: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      headers: [
        { text: 'GEO', value: 'geo', sortable: false },
        { text: 'Primary', value: 'primary', sortable: false },
        { text: 'Primary Due Date', value: 'primaryDueDate', sortable: false },
        { text: 'Secondary', value: 'secondary', sortable: false },
        { text: 'Secondary Due Date', value: 'secondaryDueDate', sortable: false },
        { text: 'QC', value: 'qc', sortable: false },
        { text: 'QC Due Date', value: 'qcDueDate', sortable: false },
        { text: 'Assignment Status', value: 'assignmentStatus', sortable: false },
        { text: 'Note', value: 'note', sortable: false },
      ],
      statusOptions: {
        notStarted: [
          { text: 'Requested', color: '#7BC4C480', icon: 'mdi-circle' },
          { text: 'Confirmed', color: '#7BC4C4', icon: 'mdi-circle' },
        ],
        inProgress: [
          { text: 'Assigned', color: '#8CA9C3', icon: 'mdi-circle' },
          { text: 'In Progress', color: '#3F599B', icon: 'mdi-circle' },
        ],
        blocked: [
          { text: 'On Hold', color: '#EBCF6D', icon: 'mdi-circle' },
        ],
        finished: [
          { text: 'Delivered', color: '#70D9A4', icon: 'mdi-circle' },
          { text: 'Canceled', color: '#FFA1A6', icon: 'mdi-circle' },
        ],
      },
      note: "",
      noteModal: false,
    };
  },
  computed: {
    itemsToShow() {
      const items = this.itemsList.map((item) => {
        return {
          geo: item.geo,
          primary: item.primaryFullName,
          primaryDueDate: item.primaryDueDate && item.primaryDueTime
            ? this.formatDateFromDB(item.primaryDueDate, item.primaryDueTime)
            : null,
          secondary: item.secondaryFullName,
          secondaryDueDate: item.secondaryDueDate && item.secondaryDueTime
            ? this.formatDateFromDB(item.secondaryDueDate, item.secondaryDueTime)
            : null,
          qc: item.qcFullName,
          qcDueDate: item.qcDueDate && item.qcDueTime
            ? this.formatDateFromDB(item.qcDueDate, item.qcDueTime)
            : null,
          assignmentStatus: item.assignmentStatus,
          assignmentTitle: item.assignmentTitle,
          payPeriod: item.payPeriod,
          deliveryDate: this.formatDateFromDB(item.deliveryDate),
        };
      })
      return items;
    },
  },
  methods: {
    getStatusClass(status) {
      return {
        'red--text': status === 'Assigned',
      };
    },
    openNote(note) {
      this.note = note;
      this.noteModal = true;
    },
    copyNote() {
      const noteTemplate = `Task ID: ${
        this.note.split(" / ")[0]
      }.\nAssignment Title: ${this.note.split(" / ")[1]}.\nAssignment Detail: ${
        this.note.split(" / ")[2]
      }.\nGEO: ${this.note.split(" / ")[3]}.\nNote: ${
        this.note.split(" / ")[4]
      }.\nCreated by: ${this.note.split(" / ")[5]}.`;
      navigator.clipboard.writeText(noteTemplate);
      this.copySuccess = true;
    },
    statusColor(status) {
      const allOptions = [
        ...this.statusOptions.notStarted,
        ...this.statusOptions.inProgress,
        ...this.statusOptions.blocked,
        ...this.statusOptions.finished,
      ];
      const statusOption = allOptions.find(
        (option) => option.text === status
      );
      return statusOption ? statusOption.color : '#000';
    },
    formatDateFromDB(dateArray, timeArray) {
      const [year, month, day] = dateArray;
      const [hours, minutes] = timeArray;

      const date = new Date(year, month - 1, day, hours, minutes);

      const options = { 
        day: '2-digit',
        month: 'short', 
        year: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit', 
        hour12: true 
      };

      const formattedDateTime = date.toLocaleString('en-US', options).replace(',', '');
      return formattedDateTime;
    },
    formatDateFromDB(date) {
      const [year, month, day] = date;
      const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      return formattedDate;
    },
    handleQueryChars(string) {
      if (string) {
        let retVal = string;
        if (retVal.includes("|")) retVal = retVal.split(" | ")[0];
        retVal = retVal.replace(/\+/g, "%2B");
        retVal = retVal.replace(/\s+$/g, "");
        return retVal;
      }
    },
    redirectToPage(item) {
      const url = `${DOMAIN}/${ROUTES.assignmentDashboard}?ppm=${moment(
          item.payPeriod
        ).format("MMMM")}&ppy=${moment(item.payPeriod).format(
          "YYYY"
        )}&Search=${this.handleQueryChars(
          item.assignmentTitle
        )}&DeliveryDate=${
          item.deliveryDate
        }`;
      window.open(url, '_blank');
    }
  },
};
</script>

<style lang="scss" scoped>
.table-row {
  background-color: #f5f5f5;
}

.red--text {
  background-color: #ffcccb;
}

.assignments-table {
  &--geo {
    text-decoration: none;
    transition: text-decoration 0.5s ease;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &--mobile {
    &__card {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgba(239, 239, 239, 0.44);
      border-radius: 10px;
      padding: 20px 30px 20px 30px;
      margin-bottom: 15px;
      &__geo {
        font-family: Roboto;
        font-size: 22px;
        font-weight: 400;
        line-height: 34px;
        text-align: left;
        color: rgba(101, 111, 125, 1);
        width: 100% !important;
        margin-bottom: 10px;
      }
      &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100% !important;
        &--title {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-family: Roboto;
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
          color: rgba(101, 111, 125, 1);
          width: 220px !important;
        }
        &--data {
          font-family: Roboto;
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
          color: rgba(0, 0, 0, 1);
          width: 100% !important;
          height: 40px;
          border-radius: 4px;
          background-color: #FFF !important;
          display: flex;
          align-items: center;
          padding-left: 8px;
          & .pmdashboard__task--content-left--chip {
            color: #fff !important;
            height: 27px !important;
            margin-left: 8px !important;
          }
        }
      }
    }
  }
}

.hover-pointer {
  cursor: pointer;
}
</style>
