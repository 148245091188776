<template>
  <v-form
    ref="form"
    :disabled="!allowedToEdit"
    v-model="valid"
    lazy-validation
    class="v-card v-sheet theme--light"
  >
    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab v-for="step in steps" :key="step">
        {{ step }}
      </v-tab>
      <v-btn
        v-if="isEditMode && (isAdmin || isManager)"
        class="ma-1"
        height="40px"
        small
        color="secondary"
        :loading="deletionProcessing"
        @click="handleRecordDeletion"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0">
              <v-autocomplete
                outlined
                label="Related Project"
                :items="projects"
                :loading="formLoading"
                v-model="relatedProject"
                item-text="name"
                return-object
                disabled
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-text-field
                outlined
                label="Related Purchase Order"
                :loading="formLoading"
                v-model="purchaseOrderNumber"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-menu
                v-model="requestDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :loading="formLoading"
                    v-model="formattedRequestDate"
                    :rules="[rules.required]"
                    label="Request Date"
                    prepend-icon="mdi-calendar-plus"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="requestDateMenu = false"
                  v-model="requestDate"
                  :min="allowedCalendarDates"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Related Client Requester"
                :items="clientRequesters"
                :loading="formLoading"
                v-model="clientRequester"
                :item-text="fullName"
                :disabled="!isAdmin && !isManager"
                :rules="[rules.requiredObject]"
                @change="updatePO(relatedProject)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Request Type"
                :items="requestTypes"
                :loading="formLoading"
                v-model="requestType"
                :disabled="!isAdmin && !isManager"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-combobox
                outlined
                label="Assignment Title"
                :items="assignmentTitles"
                :loading="formLoading"
                v-model="assignmentName"
                :rules="[rules.required]"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                outlined
                label="Assignment Detail"
                :loading="formLoading"
                v-model="assignmentDetails"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Media Type"
                :items="mediaTypes"
                :loading="formLoading"
                v-model="mediaType"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Keyword"
                :items="keywords"
                :loading="formLoading"
                v-model="keyword"
                :disabled="!isAdmin && !isManager"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item eager>
        <v-container>
          <v-row>
            <v-col cols="12" sm="4" class="pb-0">
              <v-autocomplete
                outlined
                label="GEO"
                :disabled="!isAdmin && !isManager"
                :items="geoOptions"
                :loading="formLoading"
                v-model="geo"
                :rules="[rules.required]"
                @change="handleWorkTypeRef($event, true)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-text-field
                outlined
                label="Word Count"
                type="number"
                :loading="formLoading"
                v-model="wordCount"
              />
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
              <v-menu
                v-model="deliveryDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :loading="formLoading"
                    v-model="formattedDeliveryDate"
                    :rules="[rules.required]"
                    label="Delivery Date"
                    prepend-icon="mdi-calendar-check"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="deliveryDateMenu = false"
                  v-model="deliveryDate"
                  :min="allowedCalendarDates"
                  @change="handlePayPeriod($event)"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Primary"
                :disabled="!isAdmin && !isManager"
                :items="relatedWriters"
                :item-text="fullName"
                return-object
                :loading="formLoading"
                v-model="writer"
                :rules="[rules.requiredObject]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-menu
                v-model="payPeriodMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :loading="formLoading"
                    v-model="payPeriod"
                    :rules="[rules.required]"
                    label="Pay Period"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="payPeriodMenu = false"
                  v-model="payPeriod"
                  type="month"
                  :min="allowedCalendarDates"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Work Type Reference"
                :items="workType1References"
                v-model="workType1Reference"
                item-text="workTypeName"
                return-object
                :loading="formLoading"
                :disabled="!isAdmin && !isManager"
                :rules="[rules.requiredObject]"
                @change="handleRequester($event)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" class="pb-0">
              <v-autocomplete
                outlined
                label="Work Type Quantity"
                :items="workType1Quantities"
                :loading="formLoading"
                v-model="workType1Quantity"
                :rules="[rules.required]"
              ></v-autocomplete></v-col
          ></v-row>
          <v-row>
            <v-col cols="12" sm="3" class="d-flex justify-center">
              <v-checkbox
                class="mt-0"
                v-model="spellingTypos"
                label="Spelling/Typos"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex justify-center">
              <v-checkbox
                class="mt-0"
                v-model="grammarSyntax"
                label="Grammar/Syntax"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex justify-center">
              <v-checkbox
                class="mt-0"
                v-model="factualError"
                label="Factual Error"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex justify-center">
              <v-checkbox
                class="mt-0"
                v-model="styleToV"
                label="Style/ToV"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-textarea
                outlined
                clearable
                clear-icon="mdi-close-circle-outline"
                label="Comments"
                :loading="formLoading"
                v-model="note"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <v-card-actions class="d-flex justify-end pr-4 pb-4">
      <v-btn
        v-if="allowedToEdit"
        width="20%"
        color="secondary"
        :loading="recordProcessing"
        @click="handleRecord"
        >{{ `${isEditMode ? "Update" : "Add"} Record` }}</v-btn
      >
    </v-card-actions>
  </v-form>
</template>

<script>
// vuex
import { mapGetters } from "vuex";
// internal
import {
  getAllProjects,
  getEnum,
  getCustomerPriceList,
  processAssignment,
  deleteAssignment,
} from "@/utils/newDbUtils";
import { timeUnits } from "@/utils/mixins";

export default {
  name: "LEDForm",
  props: {
    geoOptions: {
      type: Array,
      required: true,
    },
    staffList: {
      type: Array,
      required: true,
    },
    itemToEdit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      relatedProject: null,
      requestDate: null,
      clientRequester: null,
      requestType: null,
      mediaType: null,
      keyword: null,
      deliveryDate: null,
      geo: null,
      po: null,
      wordCount: null,
      assignmentDetails: null,
      assignmentName: null,
      writer: null,
      payPeriod: null,
      workType1References: [],
      workType1Reference: null,
      workType1Quantity: null,
      spellingTypos: false,
      grammarSyntax: false,
      factualError: false,
      styleToV: false,
      note: null,
      tab: null,
      steps: ["General Info", "Feedback"],
      projects: [],
      requestDateMenu: false,
      formLoading: true,
      clientRequesters: [],
      requestTypes: [],
      clientDeliveryDateMenu: false,
      deliveryDateMenu: false,
      mediaTypes: [],
      keywords: [],
      poLoading: false,
      titleGeoReferences: [
        {
          geos: ["es-ES", "es-MX"],
          titleGEO: "ESP",
        },
        {
          geos: ["nl-NL"],
          titleGEO: "NDL",
        },
        {
          geos: ["uk-UA"],
          titleGEO: "UKR",
        },
        {
          geos: ["ar-SA"],
          titleGEO: "ARA",
        },
        {
          geos: ["cs-CZ"],
          titleGEO: "CZE",
        },
        {
          geos: ["ru-RU"],
          titleGEO: "RUS",
        },
      ],
      workType1Quantities: [
        0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5, 2.75, 3.0, 3.25,
      ],
      payPeriodMenu: false,
      editMode: false,
      recordProcessing: false,
      deletionProcessing: false,
      qbID: null,
      valid: true,
      rules: {
        required: (value) => !!value || value === 0 || "Required",
        requiredObject: (value) =>
          (value ? !!Object.keys(value).length : !!value) || "Required",
      },
    };
  },
  mounted() {
    this.handleDropdowns();
  },
  computed: {
    ...mapGetters("auth", ["user", "isAdmin", "isManager"]),
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
    assignmentTitles() {
      return [
        "Q&A with Cue team",
        "Editorial Call with Cue team",
        "Editorial Call with Client",
        `Review of Localized ${
          this.titleGeoReferences.find((el) => el.geos.includes(this.geo))
            ?.titleGEO ?? "GEO"
        } Copy`,
        `Review of Original ${
          this.titleGeoReferences.find((el) => el.geos.includes(this.geo))
            ?.titleGEO ?? "GEO"
        } Copy`,
        "Glossary Development",
        "Guidelines Development",
      ];
    },
    allowedCalendarDates() {
      return this.isAdmin || this.isManager
        ? null
        : this.$moment().startOf("month").format("YYYY-MM-DD");
    },
    allowedToEdit() {
      return (
        this.isAdmin ||
        this.isManager ||
        !this.isEditMode ||
        (this.$moment(this.itemToEdit.schedule.deliveryDate).isSameOrAfter(
          this.allowedCalendarDates
        ) &&
          this.itemToEdit?.createdBy === this.user.user.id)
      );
    },
    formattedRequestDate: {
      get() {
        return this.formatPickerDate(this.requestDate);
      },
      set() {
        this.requestDate = null;
      },
    },
    formattedDeliveryDate: {
      get() {
        return this.formatPickerDate(this.deliveryDate);
      },
      set() {
        this.deliveryDate = null;
      },
    },
    purchaseOrderNumber() {
      return this.po?.purchaseOrderNumber ?? null;
    },
    relatedWriters() {
      if (this.isAdmin || this.isManager)
        return this.geo
          ? this.staffList.filter((el) =>
              el.adminDetails.geos.includes(this.geo)
            )
          : [];
      else return [this.user];
    },
  },
  mixins: [timeUnits],
  watch: {
    itemToEdit() {
      this.handleEditMode();
    },
  },
  methods: {
    fullName(item) {
      return `${item?.user?.firstName} ${item?.user?.lastName}`;
    },
    handlePayPeriod(dateInput) {
      if (!this.payPeriod) this.payPeriod = dateInput.slice(0, -3);
    },
    updatePO(selectedProject) {
      if (
        selectedProject &&
        this.clientRequester?.user?.firstName !== "Company" &&
        this.clientRequester?.user?.lastName !== "Cue, Inc."
      )
        this.po =
          selectedProject.purchaseOrders?.find((el) => el.isActive) ?? null;
      else this.po = null;
    },
    updateRelatedProjectSmartFields(selectedProject, itemToEditHandling) {
      if (selectedProject) {
        this.clientRequesters = selectedProject.clientRequesters
          ?.filter((el) => el.active)
          ?.sort((a, b) =>
            a?.user?.firstName?.localeCompare(b?.user?.firstName)
          );
        this.clientRequester = itemToEditHandling
          ? this.clientRequesters.find(
              (el) => el.user.id === this.itemToEdit.clientRequester.id
            )
          : null;
        this.po = itemToEditHandling
          ? this.itemToEdit.pricing.purchaseOrder ?? null
          : this.updatePO(selectedProject);
      } else {
        if (!itemToEditHandling) this.po = this.clientRequester = null;
        this.clientRequesters = [];
      }
    },
    getRelatedProjects() {
      return new Promise((resolve, reject) => {
        getAllProjects("iTunes").then((data) => {
          this.projects = data;
          this.relatedProject = { ...data[0] };
          resolve(this.updateRelatedProjectSmartFields(this.relatedProject));
        });
      });
    },
    getRequestTypes() {
      return new Promise((resolve, reject) => {
        getEnum("RequestType")
          .then((data) => resolve((this.requestTypes = data)))
          .catch((err) => reject(err));
      });
    },
    getMediaTypes() {
      return new Promise((resolve, reject) => {
        getEnum("MediaType")
          .then((data) => resolve((this.mediaTypes = data)))
          .catch((err) => reject(err));
      });
    },
    getKeywords() {
      return new Promise((resolve, reject) => {
        getEnum("Keyword")
          .then((data) => resolve((this.keywords = data)))
          .catch((err) => reject(err));
      });
    },
    getWorkTypes() {
      return new Promise((resolve, reject) => {
        getCustomerPriceList()
          .then((data) =>
            resolve(
              (this.workType1References = data.filter(
                (wt) =>
                  wt.workTypeName === "ESV_2500-00-00" ||
                  wt.workTypeName === "ESV_3000-00-00" ||
                  wt.workTypeName === "ESV_3000-00-4500" ||
                  wt.workTypeName === "ESV_3500-00-4500" ||
                  wt.workTypeName === "ESV_3500-00-5500" ||
                  wt.workTypeName === "ESV_2500-00-4500"
              ))
            )
          )
          .catch((err) => reject(err));
      });
    },
    handleDropdowns() {
      Promise.all([
        this.getRelatedProjects(),
        this.getRequestTypes(),
        this.getMediaTypes(),
        this.getKeywords(),
        this.getWorkTypes(),
      ])
        .then(() => {
          this.formLoading = false;
          this.handleEditMode();
        })
        .catch((err) => {
          console.error(err);
          this.formLoading = false;
        });
    },
    handleWorkTypeRef(selectedGeo, reset) {
      if (this.assignmentName?.includes("Copy") && reset)
        this.assignmentName = null;
      if (reset) this.writer = null;
      this.workType1Reference = this.workType1References.find((el) =>
        selectedGeo === "ar-SA"
          ? el.workTypeName === "ESV_3500-00-4500"
          : selectedGeo === "nl-NL" || selectedGeo === "de-DE" || this.user.user.id === 983
          ? el.workTypeName === "ESV_3500-00-5500"
          : selectedGeo === "cs-CZ"
          ? el.workTypeName === "ESV_2500-00-00"
          : el.workTypeName === "ESV_3000-00-4500"
      );
      if (this.user.user.id === 1478) {
        this.workType1Reference = this.workType1References.find((el) =>
          el.workTypeName === "ESV_2500-00-4500"
        );
      }
      this.handleRequester(this.workType1Reference);
    },
    handleRequester(selectedRef) {
      this.clientRequester = this.clientRequesters.find((el) =>
        selectedRef.workTypeName === "ESV_2500-00-00" ||
        selectedRef.workTypeName === "ESV_3000-00-00"
          ? el?.user?.firstName === "Company" &&
            el?.user?.lastName === "Cue, Inc."
          : el?.user?.firstName === "Pilar" && el?.user?.lastName === "Blanco"
      );
      this.updatePO(this.relatedProject);
    },
    acronymize(string) {
      return string
        .split(/\s/)
        .reduce(
          (response, word) => (response += word.slice(0, 1).toUpperCase()),
          ""
        );
    },
    handleRecord() {
      if (this.$refs.form.validate()) {
        this.recordProcessing = true;
        const data = {
          projectId: this.relatedProject.id,
          clientId: this.relatedProject.client?.id,
          clientRequesterId: typeof this.clientRequester === 'string'
            ? this.relatedProject.clientRequesters.find(el => el.user.firstName === this.clientRequester.split(" ")[0] && el.user.lastName === this.clientRequester.split(" ")[1]).user.id
            : this.clientRequester.user.id,
          pricing: {
            purchaseOrderId: this.po?.id ?? null,
            workTypes: [
              {
                customerPriceListId: this.workType1Reference.id,
                quantity: this.workType1Quantity,
                orderPosition: 1
              },
            ],
          },
          assignmentDetails: {
            assignmentDetail: this.assignmentDetails,
            assignmentTitle: this.assignmentName,
            geo: this.geo,
            mediaType: this.mediaType,
            wordCount: this.wordCount,
            keyword: this.keyword,
            note: this.note,
            requestType: this.requestType,
            assignmentStatus:
              this.itemToEdit?.assignmentDetails?.assignmentStatus ??
              "Delivered",
            emailSubjectLine:
              this.itemToEdit?.assignmentDetails?.emailSubjectLine ??
              this.assignmentName,
            fileLocation:
              this.itemToEdit?.assignmentDetails?.fileLocation ?? "Dropbox",
            taskId:
              this.itemToEdit?.assignmentDetails?.taskId ??
              `${this.keyword}_${this.$moment(this.requestDate).format(
                "MMDDYYYY"
              )}-${this.acronymize(this.assignmentDetails)}-${
                this.writer.adminDetails.initials
              }`,
          },
          primaryId: this.writer.user.id,
          schedule: {
            payPeriod: this.payPeriod,
            requestDate: this.requestDate,
            deliveryDate: this.deliveryDate,
            primaryDueDate:
              this.itemToEdit?.schedule?.primaryDueDate ?? this.deliveryDate,
            deliveryTime: this.itemToEdit?.schedule?.deliveryTime ?? "05:00 PM",
            primaryDueTime:
              this.itemToEdit?.schedule?.primaryDueTime ?? "05:00 PM",
          },
          leadEditorDetails: {
            spellingOrTypos: this.spellingTypos,
            grammarOrSyntax: this.grammarSyntax,
            factualError: this.factualError,
            styleOrTov: this.styleToV,
          },
        };
        processAssignment({
          data,
          id: this.itemToEdit?.id,
        })
          .then((resp) => {
            this.recordProcessing = false;
            this.$emit("led-dashboard-table-refresh", resp);
          })
          .catch((err) => {
            console.error(err);
            this.recordProcessing = false;
          });
      }
    },
    handleRecordDeletion() {
      this.deletionProcessing = true;
      deleteAssignment(this.itemToEdit.id)
        .then((resp) => {
          this.deletionProcessing = false;
          this.$emit("led-dashboard-table-refresh", resp);
        })
        .catch((err) => {
          console.error(err);
          this.deletionProcessing = false;
        });
    },
    handleEditMode() {
      if (!this.isEditMode) this.$refs.form.resetValidation();
      this.relatedProject = this.projects.find((el) =>
        this.isEditMode
          ? el.id === this.itemToEdit.project?.id
          : el.name === "iTunes FC"
      );
      this.po =
        this.relatedProject.purchaseOrders?.find((el) =>
          this.isEditMode
            ? el.id === this.itemToEdit?.pricing?.purchaseOrder?.id
            : el.isActive
        ) ?? null;
      this.clientRequester = this.isEditMode
        ? this.clientRequesters.find(
            (el) => el.user.id === this.itemToEdit.clientRequester.id
          )
        : this.relatedProject.clientRequesters.find(
            (el) =>
              el?.user?.firstName === "Pilar" && el?.user?.lastName === "Blanco"
          );
      this.requestType = this.isEditMode
        ? this.itemToEdit.assignmentDetails.requestType
        : "Editorial Supervision";
      this.keyword = this.isEditMode
        ? this.itemToEdit.assignmentDetails.keyword
        : "QaS";
      this.writer = this.isEditMode
        ? this.staffList.find((el) => el.user.id === this.itemToEdit.primary.id)
        : this.user;
      this.deliveryDate = this.isEditMode
        ? this.itemToEdit.schedule.deliveryDate
        : null;
      this.mediaType = this.isEditMode
        ? this.itemToEdit.assignmentDetails.mediaType
        : null;
      this.requestDate = this.isEditMode
        ? this.itemToEdit.schedule.requestDate
        : this.$moment(
            new Date().toLocaleString("en-US", {
              timeZone: "America/New_York",
            })
          ).format("YYYY-MM-DD");
      this.geo = this.isEditMode
        ? this.itemToEdit.assignmentDetails.geo
        : this.user.user.id === 983
        ? "de-DE"
        : this.user.user.id === 1478
        ? "es-MX"
        : this.user.adminDetails.geos[0];
      this.note = this.isEditMode
        ? this.itemToEdit.assignmentDetails.note
        : null;
      this.wordCount = this.isEditMode
        ? this.itemToEdit.assignmentDetails.wordCount
        : null;
      this.assignmentDetails = this.isEditMode
        ? this.itemToEdit.assignmentDetails.assignmentDetail
        : null;
      this.assignmentName = this.isEditMode
        ? this.itemToEdit.assignmentDetails.assignmentTitle
        : null;
      this.payPeriod = this.isEditMode
        ? this.itemToEdit.schedule.payPeriod
        : null;
      this.workType1Quantity = this.isEditMode
        ? this.itemToEdit.pricing.workTypes[0].quantity
        : null;
      this.spellingTypos = this.isEditMode
        ? this.itemToEdit.leadEditorDetails.spellingOrTypos
        : false;
      this.grammarSyntax = this.isEditMode
        ? this.itemToEdit.leadEditorDetails.grammarOrSyntax
        : false;
      this.factualError = this.isEditMode
        ? this.itemToEdit.leadEditorDetails.factualError
        : false;
      this.styleToV = this.isEditMode
        ? this.itemToEdit.leadEditorDetails.styleOrTov
        : false;
      this.workType1Reference = this.isEditMode
        ? this.workType1References.find(
            (el) =>
              el.id ===
              this.itemToEdit.pricing.workTypes[0].customerPriceList.id
          )
        : null;
      if (!this.isEditMode) this.handleWorkTypeRef(this.geo);
    },
  },
};
</script>

<style lang="scss" scoped></style>
