// vuex
import store from "@/store/index";
// libraries
import moment from "moment";
// internal
import { processAssignment, getCustomerPriceList, processAssignmentsList } from "@/utils/newDbUtils";
import { publishMessage } from "@/utils/slackUtils";
import {
  getFCWorkType,
  getFCWordCount,
  getFCRequestType,
  isAdaptation,
} from "@/utils/spreadsheetUploadUtils";

export const BLANK_NEW_ASSIGNMENT_DATAS = [
  {
    project: null,
    projectManager: null,
    clientRequester: null,
    clientDeliveryDate: null,
    clientDeliveryDue: null,
    timeZone: null,
    primary: null,
    secondary: null,
    qc: null,
    assignmentDetails: {
      assignmentId: null,
      assignmentDetail: null,
      assignmentTitle: null,
      clientFeedback: null,
      fileLocation: null,
      genre: null,
      geo: null,
      taskId: null,
      sourceGeo: null,
      mediaType: null,
      primaryAccepted: null,
      secondaryAccepted: null,
      assignmentStatus: "Assigned",
      wordCount: null,
      contentId: null,
      emailSubjectLine: null,
      keyword: null,
      billed: null,
      clientReviewed: null,
      note: null,
      requestType: null,
      urgent: null,
      link: null,
      slackTs: null
    },
    pricing: {
      purchaseOrder: null,
      workTypes: [{ customerPriceListId: null, quantity: null, orderPosition: 1 }],
    },
    task: {
      taskId: null,
      id: null,
    },
    document: null,
    schedule: {
      deliveryDate: null,
      deliveryTime: null,
      payPeriod: null,
      primaryDueDate: null,
      primaryDueTime: null,
      requestDate: moment(
        new Date().toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      ).format("YYYY-MM-DD"),
      secondaryDueDate: null,
      secondaryDueTime: null,
      qcDueDate: null,
      qcDueTime: null,
    },
  },
];

export const amountOfEngilshAdaptations = (geos) => {
  const enAdaptations = ["en-GB", "en-CA", "en-AU"];
  return geos.every((geo) => enAdaptations.includes(geo));
};

//////////////////////////////
// assignment CRUD
//////////////////////////////
export const formatBackendData = (data) => {
  const retVal = {
    ...data,
    clientId: data.project?.client?.id,
    clientRequesterId: data.clientRequester?.user?.id,
    pricing: {
      purchaseOrderId: data.pricing?.purchaseOrder === -1 ?
        -1 :
        data.pricing?.purchaseOrder?.id ?? null,
      workTypes: data.pricing?.workTypes?.map((el) => ({
        customerPriceListId: el?.customerPriceList ? el?.customerPriceList?.id : null,
        quantity: el?.quantity || el?.quantity === 0 ? +el?.quantity : null,
        orderPosition: el?.orderPosition
      })),
    },
    primaryId: data.primary?.user?.id,
    secondaryId: data.secondary?.user?.id ?? null,
    qcId: data.qc?.user?.id ?? null,
    projectId: data.project?.id,
    projectManagerId: store.getters["assignment/bulkEditing"]
      ? data.projectManager?.user?.id
      : data.project?.projectManager?.id ?? null,
    writerSlackID:
      data.primary?.adminDetails?.[
        `slackId${data.project?.name === "iTunes FC" ? "Fc" : "Staff"}`
      ],
    editorSlackID:
      data.secondary?.adminDetails?.[
        `slackId${data.project?.name === "iTunes FC" ? "Fc" : "Staff"}`
      ],
    reviewerSlackID:
      data.qc?.adminDetails?.[
        `slackId${data.project?.name === "iTunes FC" ? "Fc" : "Staff"}`
      ],
  };
  // make clean PATCH object for bulk edit
  const cleanObject = (object) => {
    Object.entries(object).forEach(([k, v]) => {
      if (v && typeof v === "object") cleanObject(v);
      if (
        (v && typeof v === "object" && !Object.keys(v).length) ||
        v === null ||
        v === undefined ||
        (Array.isArray(v) && v.length === 0)
      ) {
        if (Array.isArray(object)) object.splice(k, 1);
        else if (!(v instanceof Date)) delete object[k];
      }
    });
    return object;
  };
  if (store.getters["assignment/bulkEditing"]) cleanObject(retVal);
  return retVal;
};

export const processAssignmentsWithDatas = async (datas) => {
  const backendUploadDatas = datas.map((data) => {
    let assignment = { ...formatBackendData(data) };
    return assignment;
  });
  backendUploadDatas.forEach( object => {
    if (object.pricing.workTypes.length === 1 && object.pricing.workTypes[0].quantity === 0 && !object.pricing.workTypes[0].hasOwnProperty('customerPriceListId')) {
      object.pricing.workTypes = null;
    }
  })
  try {
    const serverResponse = await addAssignmentsFromForm(backendUploadDatas);
    // process the array of API respones for flash message
    const rejected = serverResponse.filter((el) => el.status >= 400);
    let rejectedResponse = null;
    if (rejected.length) {
      const errMsg = rejected.map((el) => el.data.message).toString();
      rejectedResponse = { data: { statusCode: 500, message: errMsg } };
    }
    return rejectedResponse ?? { data: { statusCode: 200 } };
  } catch (err) {
    console.error(err);
    return err;
  }
};

const addAssignmentsFromForm = async (datas) => {
  let retVal = [];
  for (let i = 0; i < datas.length; i++) {
    try {
      const resp = await processAssignment({
        data: datas[i],
        id: datas[i]?.id,
        batchEdit: store.getters["assignment/bulkEditing"],
      });
      retVal.push(resp);
      if (
        (resp.status === 200 || resp.status === 201) &&
        !store.getters["assignment/bulkEditing"]
      ) {
        // process the GEO-related data for Slack notification
        const [writer, editor, reviewer] = [
          datas[i].writerSlackID
            ? `<@${datas[i].writerSlackID}>`
            : `${datas[i].primary.user.firstName} ${datas[i].primary.user.lastName}`,
          datas[i].editorSlackID
            ? `<@${datas[i].editorSlackID}>`
            : datas[i].secondary
            ? `${datas[i].secondary.user.firstName} ${datas[i].secondary.user.lastName}`
            : null,
          datas[i].reviewerSlackID
            ? `<@${datas[i].reviewerSlackID}>`
            : datas[i].qc
            ? `${datas[i].qc.user.firstName} ${datas[i].qc.user.lastName}`
            : null,
        ];
        let geoObj = {
          [datas[i].assignmentDetails.geo]: {
            assignments: [datas[i].assignmentDetails.assignmentDetail],
          },
        };
        if (writer)
          Object.assign(geoObj[datas[i].assignmentDetails.geo], {
            writers: [writer],
          });
        if (editor)
          Object.assign(geoObj[datas[i].assignmentDetails.geo], {
            editors: [editor],
          });
        if (reviewer)
          Object.assign(geoObj[datas[i].assignmentDetails.geo], {
            reviewers: [reviewer],
          });
        if (!datas[i]?.id) {
          const geoKeys = [datas[i].assignmentDetails?.geo];
          if (geoKeys 
            && geoKeys.length 
            && geoKeys.includes("de-CH")
            && datas[i].assignmentDetails.requestType === "Adaptation") {
            await publishMessage({
              mode:
                datas[i].project?.name === "iTunes FC"
                  ? "fcNotification"
                  : "nonFcNotification",
              item: {
                ...datas[i],
                assignment: datas[i].assignmentDetails.assignmentDetail,
                geos: [geoObj],
                id: resp.data.id,
              },
              modifier: "de-CH-adaptation",
            });
          } else
          await publishMessage({
            mode:
              datas[i].project?.name === "iTunes FC"
                ? "fcNotification"
                : "nonFcNotification",
            item: {
              ...datas[i],
              assignment: datas[i].assignmentDetails.assignmentDetail,
              geos: [geoObj],
              id: resp.data.id,
            },
          });
        } else if (datas[i]?.id && datas[i].project?.name !== "iTunes FC") {
          await publishMessage({
            mode: "nonFcNotification",
            item: {
              ...datas[i],
              assignment: datas[i].assignmentDetails.assignmentDetail,
              geos: [geoObj],
              id: resp.data.id,
            },
          });
        }
      }
    } catch (err) {
      console.error(err);
      retVal.push(err);
    }
  }
  return retVal;
};

export const addAssignmentRowsToDB = (
  formValues,
  tableRows,
  staffAssignments,
  staffDatas
) => {
  return new Promise(async (resolve, reject) => {
    const priceList = await getCustomerPriceList();

    const shakespeare = store.getters["spreadsheetUpload/shakespeare"],
      shakespeareLoc = store.getters["spreadsheetUpload/shakespeareLoc"],
      backstageCopywriting =
        store.getters["spreadsheetUpload/backstageCopywriting"],
      cueLoc = store.getters["spreadsheetUpload/cueLoc"],
      cueCopywriting = store.getters["spreadsheetUpload/cueCopywriting"],
      geosWithIndividualDeadlines = formValues.individualGeosDeadlines.map(
        (geo) => Object.keys(geo)[0]
      );

    let promiseList = [];

    // form the Slack notification data
    let notification = {
      project: formValues.relatedProject,
      assignment: tableRows[0].task_name,
      assignmentDetails: {
        keyword: tableRows[0].keyword,
        mediaType: shakespeare ? formValues.mediaType : tableRows[0].media_type,
        requestType: tableRows[0].request_type,
        fileLocation: formValues.fileLocation,
        assignmentTitle: formValues.assignmentTitle,
        wordCount: tableRows[0].word_count,
      },
      schedule: {
        payPeriod: formValues.payPeriod,
        primaryDueDate: formValues.writingDueDate,
        primaryDueTime: formValues.writingDue,
        secondaryDueDate: formValues.editingDueDate,
        secondaryDueTime: formValues.editingDue,
        qcDueDate: formValues.reviewingDueDate,
        qcDueTime: formValues.reviewingDue,
        deliveryDate: formValues.deliveryDate,
      },
      geos: [],
    };
    // init the Non-FC notifications
    let nonFcNotifications = [];

    const getGeoIndex = (geo, array) =>
        array.findIndex((el) => el.hasOwnProperty(geo)),
      applyIndividualGeoDeadlines = () => {
        const geosDeadlines = [...formValues.individualGeosDeadlines];
        geosWithIndividualDeadlines.forEach((geo) => {
          Object.assign(
            notification.geos[getGeoIndex(geo, notification.geos)][geo],
            {
              writingDueDate:
                geosDeadlines[getGeoIndex(geo, geosDeadlines)][geo]
                  .writingDueDate,
              writingDue:
                geosDeadlines[getGeoIndex(geo, geosDeadlines)][geo].writingDue,
              editingDueDate:
                geosDeadlines[getGeoIndex(geo, geosDeadlines)][geo]
                  .editingDueDate,
              editingDue:
                geosDeadlines[getGeoIndex(geo, geosDeadlines)][geo].editingDue,
              reviewingDueDate:
                geosDeadlines[getGeoIndex(geo, geosDeadlines)][geo]
                  .reviewingDueDate,
              reviewingDue:
                geosDeadlines[getGeoIndex(geo, geosDeadlines)][geo]
                  .reviewingDue,
            }
          );
        });
      };

    tableRows.forEach((tableRow) => {
      (shakespeare || cueCopywriting ? [tableRow.geo] : tableRow.geos).forEach(
        (geoVal) => {
          // set processed geo and individual deadlines
          const geo = shakespeareLoc || cueLoc ? geoVal.geo : geoVal,
            geoIndividualData =
              formValues.individualGeosDeadlines[
                getGeoIndex(geo, formValues.individualGeosDeadlines)
              ]?.[geo];
          // process work types
          const workTypes = [1, 2, 3, 4, 5, 6].map((wtype) => ({
            customerPriceListId:
              priceList.find(
                (price) =>
                  price.workTypeName ===
                  ((shakespeare && !isAdaptation({
                    locale: geo,
                    allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,
                    sourceGeo: tableRow.source_geo,
                    requestType: tableRow.request_type,
                    tableRows: tableRows,
                    shakespeare: shakespeare,
                  })) || cueCopywriting
                    ? staffAssignments[tableRow.task_id][geo][
                        `workType${wtype}Reference`
                      ]
                      ? staffAssignments[tableRow.task_id][geo][
                          `workType${wtype}Reference`
                        ]
                      : null
                    : backstageCopywriting ||
                      (tableRow.request_type === "Adaptation" && !cueLoc) || 
                      (tableRow.request_type === "Localization" && isAdaptation({locale: geo, allLocales: tableRow.geos, requestType: tableRow.request_type, sourceGeo: tableRow.source_geo}))
                    ? getFCWorkType({
                        workType:
                          staffAssignments[tableRow.task_name][geo][
                            `workType${wtype}Reference`
                          ],
                        workType1: wtype === 1,
                        locale: geo,
                        allLocales: tableRow.geos,
                        sourceGeo: tableRow.source_geo,
                        adaptationRequest:
                          tableRow.request_type === "Adaptation",
                      })
                    : wtype === 1
                    ? cueLoc
                      ? formValues.workType
                      : getFCWorkType({
                          locale: geo,
                          allLocales: shakespeare ? tableRows.map((el) => el.geo) : null,
                          sourceLocale: tableRow.source_geo,
                          localizationMode: true,
                          sourceGeo: tableRow.source_geo,
                          shakespeare: shakespeare,
                          requestType: tableRow.request_type,
                          tableRows: tableRows,
                        })
                    : shakespeare && wtype === 1
                    ? getFCWorkType({
                        workType: null,
                        workType1: wtype === 1,
                        locale: geo,
                        allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,
                        sourceGeo: tableRow.source_geo,
                        shakespeare: shakespeare,
                        requestType: tableRow.request_type,
                        tableRows: tableRows,
                        adaptationRequest:
                        tableRow.request_type === "Adaptation",
                      }) : null)
              )?.id ?? null,
            quantity:
              (shakespeare && !isAdaptation({
                locale: geo,
                allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,
                sourceGeo: tableRow.source_geo,
                requestType: tableRow.request_type,
                tableRows: tableRows,
                shakespeare: shakespeare,
              })) || cueCopywriting
                ? staffAssignments[tableRow.task_id][geo][
                    `workType${wtype}Quantity`
                  ]
                  ? staffAssignments[tableRow.task_id][geo][
                      `workType${wtype}Quantity`
                    ]
                  : null
                : backstageCopywriting
                ? getFCWordCount({
                    wordCount:
                      staffAssignments[tableRow.task_name][geo][
                        `workType${wtype}Quantity`
                      ],
                    locale: geo,
                    allLocales: tableRow.geos,
                  })
                : wtype === 1
                ? tableRow.request_type === "Adaptation" && !cueLoc && !amountOfEngilshAdaptations(tableRow.geos)
                  ? null
                  : tableRow.word_count
                : null,
            orderPosition: wtype
          }));
          // form assignments list for POST
          promiseList.push({
              projectId: formValues.relatedProject.id,
              schedule: {
                deliveryDate: formValues.deliveryDate,
                deliveryTime: formValues.deliveryDue,
                payPeriod: formValues.payPeriod,
                primaryDueDate:
                  geoIndividualData?.writingDueDate ??
                  formValues.writingDueDate,
                primaryDueTime:
                  geoIndividualData?.writingDue ?? formValues.writingDue,
                secondaryDueDate: staffAssignments[
                  tableRow[
                    `task_${shakespeare || cueCopywriting ? "id" : "name"}`
                  ]
                ][geo].editor
                  ? geoIndividualData?.editingDueDate ??
                    formValues.editingDueDate ??
                    null
                  : null,
                secondaryDueTime: staffAssignments[
                  tableRow[
                    `task_${shakespeare || cueCopywriting ? "id" : "name"}`
                  ]
                ][geo].editor
                  ? geoIndividualData?.editingDue ?? formValues.editingDue
                  : null,
                qcDueDate: staffAssignments[
                  tableRow[
                    `task_${shakespeare || cueCopywriting ? "id" : "name"}`
                  ]
                ][geo].reviewer
                  ? geoIndividualData?.reviewingDueDate ??
                    formValues.reviewingDueDate ??
                    null
                  : null,
                qcDueTime: staffAssignments[
                  tableRow[
                    `task_${shakespeare || cueCopywriting ? "id" : "name"}`
                  ]
                ][geo].reviewer
                  ? geoIndividualData?.reviewingDue ?? formValues.reviewingDue
                  : null,
                requestDate: moment(tableRow.request_date).format(
                  "YYYY-MM-DD"
                ),
                requestTime:
                  shakespeare || cueLoc || cueCopywriting
                    ? formValues.requestTime
                    : null,
              },
              task: {
                taskId:
                  cueCopywriting && tableRow.task_id === tableRow.task_name
                    ? null
                    : tableRow.task_id,
              },
              assignmentDetails: {
                assignmentDetail: shakespeareLoc
                  ? tableRow.true_task_name
                  : tableRow.task_name,
                assignmentId:
                  shakespeare || cueCopywriting
                    ? null
                    : staffAssignments[tableRow.task_name][geo]
                        .assignmentId ?? null,
                assignmentStatus: cueCopywriting
                  ? tableRow.status
                  : "Assigned",
                assignmentTitle: cueLoc
                  ? staffAssignments[tableRow.task_name].assignmentTitle
                  : formValues.assignmentTitle,
                contentId:
                  shakespeare || shakespeareLoc
                    ? tableRow.content_id ?? tableRow.video_content_id
                    : null,
                emailSubjectLine: shakespeare
                  ? tableRow.task_name
                  : tableRow.subject_line ?? null,
                fileLocation: formValues.fileLocation,
                genre: null,
                geo,
                keyword: tableRow.keyword ?? null,
                link: formValues.filmLink
                  ? formValues.filmLink
                  : shakespeare 
                  ? null 
                  : tableRow.shakespeare_link?.length
                  ? tableRow.shakespeare_link.find((el) => el.geo === geo).shakespeare_link
                  : tableRow.link ?? null,
                mediaType: shakespeare
                  ? formValues.mediaType
                  : tableRow.media_type,
                note: formValues.note,
                requestType: shakespeare || backstageCopywriting || tableRow.request_type === "Localization"
                  ? getFCRequestType({
                      locale: geo,
                      allLocales: shakespeare ? tableRows.map((el) => el.geo) : tableRow.geos,
                      sourceGeo: tableRow.source_geo,
                      requestType: tableRow.request_type,
                      tableRows: tableRows,
                      shakespeare: shakespeare,
                    })
                  : tableRow.request_type,
                sourceGeo:
                  tableRow.request_type === "Adaptation" || isAdaptation({locale: geo, allLocales: tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
                  || (tableRow.source_geo === "en-GB" && tableRow.request_type === "Copywriting")
                  ? tableRow.source_geo
                  : shakespeare || cueCopywriting || isAdaptation({locale: geo, allLocales: tableRow.geos,sourceGeo: tableRow.source_geo, requestType: tableRow.request_type})
                    ? null
                    : (backstageCopywriting && !isAdaptation({locale: geo, allLocales: tableRow.geos,sourceGeo: tableRow.source_geo})) 
                      || (tableRow.request_type === "Localization" && isAdaptation({locale: geo, allLocales: tableRow.geos, sourceGeo: tableRow.source_geo, requestType: tableRow.request_type}))
                    ? isAdaptation({
                        locale: geo,
                        allLocales: tableRow.geos,
                      })
                      ? isAdaptation({
                          locale: geo,
                          getSourceLocale: true,
                        })
                      : null
                    : tableRow.source_geo,
                taskId:
                  cueCopywriting && tableRow.task_id === tableRow.task_name
                    ? null
                    : tableRow.task_id,
                urgent: formValues.urgent,
                wordCount:
                  shakespeare ||
                  cueCopywriting ||
                  backstageCopywriting ||
                  (tableRow.request_type === "Adaptation" && !cueLoc && !amountOfEngilshAdaptations(tableRow.geos))
                    ? null
                    : tableRow.word_count,
              },
              clientId: formValues.relatedProject.client?.id ?? null,
              clientRequesterId:
                formValues.relatedProject.clientRequesters?.find((req) =>
                  shakespeare || shakespeareLoc
                    ? req.user.firstName === formValues.clientRequester ||
                      req.user.lastName === formValues.clientRequester
                    : tableRow.client_requester.includes(
                        req.user.firstName
                      ) &&
                      tableRow.client_requester.includes(req.user.lastName)
                )?.user?.id ?? null,
              projectManagerId:
                formValues.relatedProject.projectManager?.id ?? null,
              primaryId: staffDatas.find(
                (primary) =>
                  primary.name ===
                  staffAssignments[
                    tableRow[
                      `task_${shakespeare || cueCopywriting ? "id" : "name"}`
                    ]
                  ][geo].writer
              ).user.id,
              secondaryId: staffAssignments[
                tableRow[
                  `task_${shakespeare || cueCopywriting ? "id" : "name"}`
                ]
              ][geo].editor
                ? staffDatas.find(
                    (secondary) =>
                      secondary.name ===
                      staffAssignments[
                        tableRow[
                          `task_${
                            shakespeare || cueCopywriting ? "id" : "name"
                          }`
                        ]
                      ][geo].editor
                  ).user.id
                : null,
              qcId: staffAssignments[
                tableRow[
                  `task_${shakespeare || cueCopywriting ? "id" : "name"}`
                ]
              ][geo].reviewer
                ? staffDatas.find(
                    (qc) =>
                      qc.name ===
                      staffAssignments[
                        tableRow[
                          `task_${
                            shakespeare || cueCopywriting ? "id" : "name"
                          }`
                        ]
                      ][geo].reviewer
                  ).user.id
                : null,
              pricing: {
                purchaseOrderId: formValues.po?.id ?? null,
                workTypes,
              },
            },
          );

          if (!cueCopywriting) {
            // process the GEO-related data for Slack notification
            const getPersonTag = (role) => {
                const person = staffDatas.find(
                    (p) =>
                      staffAssignments[
                        tableRow[`task_${shakespeare ? "id" : "name"}`]
                      ][geo][role]?.includes(p.user.firstName) &&
                      staffAssignments[
                        tableRow[`task_${shakespeare ? "id" : "name"}`]
                      ][geo][role]?.includes(p.user.lastName)
                  ),
                  slackTag = person?.[cueLoc ? "cueSlackID" : "slackID"];
                return slackTag
                  ? `<@${slackTag}>`
                  : person?.user?.firstName
                  ? `${person.user.firstName} ${person.user.lastName}`
                  : null;
              },
              [writer, editor, reviewer] = [
                getPersonTag("writer"),
                getPersonTag("editor"),
                getPersonTag("reviewer"),
              ],
              geos = notification.geos.map((el) => Object.keys(el)[0]);

            if (geos.includes(geo)) {
              const geoStaffChecker = (staffGroup, staffMember) => {
                if (
                  notification.geos[getGeoIndex(geo, notification.geos)][
                    geo
                  ].hasOwnProperty(staffGroup)
                ) {
                  if (
                    !notification.geos[getGeoIndex(geo, notification.geos)][
                      geo
                    ][staffGroup].includes(staffMember)
                  )
                    notification.geos[getGeoIndex(geo, notification.geos)][geo][
                      staffGroup
                    ].push(staffMember);
                } else {
                  Object.assign(
                    notification.geos[getGeoIndex(geo, notification.geos)][geo],
                    {
                      [staffGroup]: [staffMember],
                    }
                  );
                }
              };
              if (writer) geoStaffChecker("writers", writer);
              if (editor) geoStaffChecker("editors", editor);
              if (reviewer) geoStaffChecker("reviewers", reviewer);
            } else {
              let geoObj = { [geo]: {} };
              if (writer) Object.assign(geoObj[geo], { writers: [writer] });
              if (editor) Object.assign(geoObj[geo], { editors: [editor] });
              if (reviewer)
                Object.assign(geoObj[geo], { reviewers: [reviewer] });
              if (Object.keys(geoObj[geo]).length)
                notification.geos.push(geoObj);
            }
          }
          if (cueLoc) applyIndividualGeoDeadlines(); // add individual deadlines (Non-FC notification)
        }
      );
      if (cueLoc) {
        nonFcNotifications.push({
          ...notification,
          assignment: tableRow.task_name,
          assignmentDetails: {
            ...notification.assignmentDetails,
            requestType: tableRow.request_type,
            assignmentTitle:
              staffAssignments[tableRow.task_name].assignmentTitle,
            wordCount: tableRow.word_count,
            taskId: tableRow.task_id,
          },
        });
        notification.geos = [];
      }
    });

    if (!cueLoc && !cueCopywriting) {
      // add assignments details
      Object.entries(staffAssignments).forEach((el) => {
        const assgnDetail = shakespeare
            ? staffAssignments[el[0]].task_name
            : el[0],
          geos = shakespeare
            ? [Object.keys(el[1])[0]]
            : Object.keys(el[1]);
        geos.forEach((geo) => {
          if (geo !== "wordCount") {
            notification.geos[getGeoIndex(geo, notification.geos)][
              geo
            ].hasOwnProperty("assignments")
              ? notification.geos[getGeoIndex(geo, notification.geos)][
                  geo
                ].assignments.push(assgnDetail)
              : Object.assign(
                  notification.geos[getGeoIndex(geo, notification.geos)][geo],
                  {
                    assignments: [assgnDetail],
                  }
                );
          }
        });
      });
      applyIndividualGeoDeadlines(); // add individual deadlines (FC notification)
    }

    const enAdaptations = ["en-AU", "en-CA", "en-GB"];
    const adaptationNotifications = _.cloneDeep(notification);
    adaptationNotifications.geos = [];
    promiseList.forEach((el) => {
      if (el.assignmentDetails?.requestType === "Adaptation" && enAdaptations.includes(el.assignmentDetails?.geo)) {
        const index = notification.geos.findIndex(obj => el.assignmentDetails?.geo in obj);
        if (index !== -1) {
          const removedObject = notification.geos.splice(index, 1)[0];
          adaptationNotifications.geos.push(removedObject);
        }
      }
      if (el.assignmentDetails && el.assignmentDetails?.requestType === "Adaptation" && el.assignmentDetails?.geo === "de-CH") {
        el.assignmentDetails.sourceGeo = "de-DE";
      }
    });

    const enCopywriting = ["en-AU", "en-GB"];
    const copywritingNotifications = _.cloneDeep(notification);
    copywritingNotifications.geos = [];
    promiseList.forEach((el) => {
      if (el.assignmentDetails?.requestType === "Copywriting" && enCopywriting.includes(el.assignmentDetails?.geo)) {
        const index = notification.geos.findIndex(obj => el.assignmentDetails?.geo in obj);
        if (index !== -1) {
          const removedObject = notification.geos.splice(index, 1)[0];
          copywritingNotifications.geos.push(removedObject);
        }
      }
    });

    await processAssignmentsList(promiseList)
      .then((res) => {
        console.log('res', res)
        const failedRecords = [];
        if (res.status > 204) {
          failedRecords.push(res);
        }
        if (failedRecords.length) {
          resolve(
            failedRecords[0].data && failedRecords[0].data.statusCode ?
            `${failedRecords[0].data.statusCode}: ${failedRecords[0].data.message}` :
            `Something went wrong!`
          );
        } else {
          if (!cueCopywriting) {
            if (cueLoc) {
              const slackPromises = nonFcNotifications.map((post) => {
                const assignment = res.data.find(el => 
                  el.assignmentDetails?.assignmentDetail === post.assignment && 
                  el.assignmentDetails?.wordCount === post.assignmentDetails?.wordCount);
                const assignmentId = assignment.id;
                publishMessage({
                  mode: "nonFcNotification",
                  item: { ...post, id: assignmentId }
                })
              });
              Promise.all(slackPromises).catch((err) => reject(err));
            } else {
              if (notification.geos.length) {
                const geoKeys = notification.geos.map(el => Object.keys(el)[0]);
                if (geoKeys 
                    && geoKeys.length 
                    && geoKeys.includes("de-CH") 
                    && geoKeys.includes("de-DE")
                    && (notification.assignmentDetails.requestType === "Copywriting" || notification.assignmentDetails.requestType === "Adaptation")
                    && notification.assignmentDetails.fileLocation === "https://wrkflow.app/") {
                  const deCHContentId = promiseList.find(el => el.assignmentDetails.geo === "de-CH")?.assignmentDetails?.contentId;
                  const deDEContentId = promiseList.find(el => el.assignmentDetails.geo === "de-DE")?.assignmentDetails?.contentId;
                  if ((deCHContentId && deDEContentId && deCHContentId === deDEContentId) || backstageCopywriting) {
                    const notificationClone = _.cloneDeep(notification);
                    const notIndex = notification.geos.findIndex(obj => obj["de-CH"]);
                    const removedObject = notification.geos.splice(notIndex, 1)[0];
                    notificationClone.geos = [removedObject];
                    publishMessage({
                      mode: "fcNotification",
                      item: notificationClone,
                      modifier: "de-CH-adaptation",
                    })
                  }
                }
                publishMessage({
                  mode: "fcNotification",
                  item: notification,
                })
              }
              if (adaptationNotifications.geos.length) {
                publishMessage({
                  mode: "fcNotification",
                  item: adaptationNotifications,
                  modifier: "adaptation",
                })
              }
              if (copywritingNotifications.geos.length) {
                publishMessage({
                  mode: "fcNotification",
                  item: copywritingNotifications,
                  modifier: "copywriting",
                })
              }
            }
          } else if (cueCopywriting && promiseList[0].projectId === 91) {
            const data = [];
            promiseList.forEach((el) => {
              const existedPrimary = data.find(item => item.primary.user.id === el.primaryId);
              if (!existedPrimary) {
                const item = {
                  primary: staffDatas.find(user => user.user.id === el.primaryId),
                  secondary: el.secondaryId ? staffDatas.find(user => user.user.id === el.secondaryId) : null,
                  qc: el.qcId ? staffDatas.find(user => user.user.id === el.qcId) : null,
                  geo: [el.assignmentDetails?.geo],
                  link: el.assignmentDetails?.link,
                  files: [{
                    payPeriod: el.schedule?.payPeriod,
                    project: res.data[0].project,
                    assignmentDetails: el.assignmentDetails?.assignmentDetail
                  }],
                  link: res.data[0].assignmentDetails?.link
                }
                data.push(item);
              } else {
                if (!existedPrimary.geo.includes(el.assignmentDetails?.geo)) {
                  existedPrimary.geo.push(el.assignmentDetails?.geo);
                }
                existedPrimary.files.push({
                  payPeriod: el.schedule?.payPeriod,
                  project: res.data[0].project,
                  assignmentDetails: el.assignmentDetails?.assignmentDetail
                })
              }
            })
            publishMessage({
              mode: "filmTvMessages",
              item: notification,
              data: data
            }) 
          }
          resolve("Import Successful");
        }
      })
      .catch((err) => reject(err));
  });
};
